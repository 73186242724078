<template>
  <v-footer
    id="pages-core-footer"
    absolute
    color="transparent"
    dark
  >
    <v-container fluid>
      <v-row
        align="center"
        justify="center"
        no-gutters
      >
        <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
          &copy; 2020, made with
          <v-icon size="20">
            mdi-heart
          </v-icon>
          by Extendas.
        </div>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'PagesCoreFooter',
  }
</script>

<style lang="sass">
  #pages-core-footer
    a
      color: #FFFFFF
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
